// @flow strict
import React from 'react';
import AdSense from 'react-adsense';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Author author={author} isIndex={isIndex} />
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />

        <div className={styles['sidebar__adsense']}>
          <AdSense.Google
            style={{ display: 'block' }}
            format='auto'
            client="ca-pub-2990329700521472"
            slot="3080708032"
            responsive='true'
          />
        </div>
        
      </div>
    </div>
  );
};

export default Sidebar;
